// Classes
import { DOT } from '../../../classes/dot.class';
// Types
import { Config } from 'types/config';
import { GetInfoRequestEvent, GetInfoResponseEvent } from '../../../types/iframe';
// Constants
import { CFG_FROM_TOP_FRAME_TIMEOUT, DOT_TIMEOUT_IN_IFRAME_BRIDGE, EVENTS } from '../../../constants';

// are we in the top level?
export const isTopLevel = (win: Window = window): boolean => {
  try {
    return (win.top === win.self || !!win.Cypress) && !win.frameElement;
  } catch {
    return win.top === win.self;
  }
};

// get top level window reference
export const getTopWindow = (win: Window = window): Window => (isTopLevel(win) ? win : getTopWindow(win.parent));

export const handlerFromChildMessage = (event: MessageEvent<GetInfoRequestEvent>, dot: DOT) => {
  if (event.source && event.data.type === EVENTS.GET_INFO_EVENT) {
    event.source.postMessage(
      <GetInfoResponseEvent>{
        type: EVENTS.GET_INFO_EVENT,
        site: window.document.location.href,
        config: dot._cfg,
      },
      <WindowPostMessageOptions>'*'
    );
  }
};

const handlerFromTopMessage = (dot: DOT) => {
  let timeout: number | null = null;
  let interval: number | null = null;
  // cypress hack (cypress tests are running inside iframe -> window.top is not, what expected)
  let destination: 'top' | 'parent' = 'top';
  try {
    destination = (window as any).Cypress || (window as any).parent.Cypress ? 'parent' : 'top';
  } catch {
    // no-op
  }

  const fromTopHandler = (event: MessageEvent) => {
    if (event.data.type === EVENTS.GET_INFO_EVENT && (event.data.site || event.data.config)) {
      const info = dot.getInfo();

      info.site = event.data.site;
      info.topConfig = event.data.config;

      dot.log(`iframeBridge iframe received message with site: ${info.site} and a config: ${info.topConfig}`);
      window.clearTimeout(timeout);
      window.clearInterval(interval);
      window.removeEventListener('message', fromTopHandler);
      window.dispatchEvent(new Event(EVENTS.GET_INFO_COMPLETE));
    }
  };

  window.addEventListener('message', fromTopHandler);

  const pingTop = () =>
    window[destination].postMessage(<GetInfoRequestEvent>{ type: EVENTS.GET_INFO_EVENT }, document.referrer || '*');

  pingTop();

  interval = window.setInterval(pingTop, 250);

  timeout = window.setTimeout(() => {
    dot.log('iframeBridge: timeouted getInfo request from ' + window.location.href);
    window.clearInterval(interval);
  }, DOT_TIMEOUT_IN_IFRAME_BRIDGE);
};

export const initIframeBridge = (dot: DOT) => {
  if (isTopLevel()) {
    if (window.DOT !== dot) {
      // this should prevent registering multiple listeners, only top DOT instance should listen
      return;
    }
    // register child DOT subscriber / listener
    window.addEventListener('message', (event: MessageEvent<GetInfoRequestEvent>) =>
      handlerFromChildMessage(event, dot)
    );
  } else {
    // register top DOT subscriber / listener and try several times to get top DOT
    handlerFromTopMessage(dot);
    dot.log('iframeBridge: getInfo requested from ' + window.location.href);
  }
};

/**
 * Configure iframe DOT instance with the top window DOT configuration
 * @param dot Instance of iframe DOT
 * @returns Promise of Config object
 */
export const configureFromTopFrame = (dot: DOT): Promise<Config | null> => {
  dot.cfg({}); // because of adding PM Listeners for iframeBridge (need right config flow)

  return new Promise((resolve, reject) => {
    const cancel = window.setTimeout(() => {
      dot.log('iframeBridge timeouted', 'error');
      reject();
    }, CFG_FROM_TOP_FRAME_TIMEOUT);

    window.addEventListener(EVENTS.GET_INFO_COMPLETE, () => {
      window.clearTimeout(cancel);
      const { topConfig } = dot.getInfo();
      if (!topConfig) {
        dot.log('iframeBridge failed to get top window DOT config', 'error');
        reject();
      }

      dot.cfg(topConfig);
      dot.log('iframe DOT configured from top DOT');
      resolve(topConfig);
    });
  });
};
