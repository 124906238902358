// Types
import { Data } from '../../types/hit';
import { DOT } from '../../classes/dot.class';
import { SpenttimeIntervalConfig } from '../../types/spenttime';
// Methods
import { getParameterFromURLHash } from '../utils/general/url';
import { URL } from '@iva/utils';
// Constants
import { FIRST_SPENTTIME_SLOT, SPENTTIME_INTERVAL_CONFIG } from '../../constants/index';
import { getPerformanceEntriesBy } from '../../modules/load/load';

export const callsOnHiddenPage = [];

/**
 * Checks if page is visible
 * @returns {boolean} True if page is visible
 */
export const isPageVisible = (): boolean =>
  document.visibilityState === 'visible' || (typeof document.visibilityState === 'undefined' && !document.hidden);

/**
 * Checks if page is hidden
 * @returns {boolean} True if page is hidden
 */
export const isPageHidden = (): boolean =>
  document.visibilityState === 'hidden' || (typeof document.visibilityState === 'undefined' && document.hidden);

/**
 * Visibilitychange event handler for first time user sees page (e.g. when user switches to new opened tab)
 */
export const _firstVisibilityPageChangeHandler = (): void => {
  if (isPageVisible()) {
    callsOnHiddenPage.forEach(({ dot, data }) => {
      // eslint-disable-next-line no-use-before-define
      spenttime(dot, data);
    });
    callsOnHiddenPage.length = 0;
    document.removeEventListener('visibilitychange', _firstVisibilityPageChangeHandler);
  }
};

/**
 * Adds spenttime call to queue when page is hidden and DOT.spenttime is called (e.g. when right click on link and open new tab in Chrome)
 * @param dot DOT instance
 * @param data spenttime data
 */
export const _addToCallsQueue = (dot: DOT, data: Data): void => {
  if (!callsOnHiddenPage.length) {
    // add event listener only once
    document.addEventListener('visibilitychange', _firstVisibilityPageChangeHandler);
  }
  callsOnHiddenPage.push({ dot, data });
};

/**
 * Stops and removes spenttime interval
 */
export const clearSpenttimeInterval = (dot: DOT): void => {
  if (dot.spenttimeState?.intervalId) {
    clearTimeout(dot.spenttimeState.intervalId);
    dot.spenttimeState.intervalId = null;
  }
};

/**
 * Removes visibilitychange event handler
 */
export const clearVisibilitychangeEvent = (dot: DOT): void => {
  if (dot.spenttimeConfigured) {
    dot.spenttimeConfigured = false;
    if (dot.spenttimeState?.visibilitychangeListener) {
      document.removeEventListener('visibilitychange', dot.spenttimeState.visibilitychangeListener, false);
      document.removeEventListener('pagehide', dot.spenttimeState.visibilitychangeListener, false);
    }
  }
};

/**
 * Send spenttime hit
 * @param time Time from session start in seconds
 */
export const _hit = (dot: DOT, data: Data, time: number): void => {
  if (!dot.spenttimeConfigured) {
    return;
  }

  if (!data.d) {
    data.d = {};
  }

  data.d.action = 'spenttime';
  data.d.time = time;

  dot.hit('event', { ...data }, null, true);
};

/**
 * Tries to send spenttime hit when leaving page
 * @param dot DOT instance
 * @param data spenttime data
 * @param time spent time on page
 */
export const _blurHit = (dot: DOT, data: Data, time: number): void => {
  data.d.type = 'final';
  _hit(dot, data, time);
};

/**
 * Sends first spenttime hit when website is loaded
 * Uses first-contentful-paint from web vitals
 */
export const _fcpHit = (dot: DOT) => {
  const fcp = getPerformanceEntriesBy('name', 'first-contentful-paint', {
    startTime: null,
  } as PerformanceEntry);
  const fcpValue = fcp.startTime / 1000; // convert to seconds

  // prevent duplicate hit on SPA website
  if (dot._cfg.spa && fcpValue === dot.lastFCP) {
    return;
  }

  if (fcpValue < FIRST_SPENTTIME_SLOT) {
    dot.lastFCP = fcpValue;
    _hit(dot, dot.spenttimeState.data, fcpValue);
  }
};

/**
 * Creates sequence in which spenttime hits are sent, based on SPENTTIME_INTERVAL_CONFIG
 * First hit is sent in 5s, second 10s, third 30s,then every 30sec till 4 minutes,then every  minute till 15 minutes after that every 5 minutes till one hour
 */
export const _createIntervalSequence = (
  config: SpenttimeIntervalConfig[],
  spenttimeDuration: number
): { intervalSequence: number[]; totalTime: number } => {
  return config.reduce(
    ({ intervalSequence, totalTime }, { endOfInterval, interval }, index) => {
      const currentEndOfInterval = index === config.length - 1 ? spenttimeDuration : endOfInterval;
      while (totalTime < currentEndOfInterval) {
        totalTime += interval;
        intervalSequence = [...intervalSequence, interval];
      }
      return { intervalSequence, totalTime };
    },
    { intervalSequence: [], totalTime: 0 }
  );
};

/**
 * Creates timeout, which sends spenttime hit and calls next iteration
 */
export const _createInterval = (dot: DOT) => {
  const {
    spenttimeState,
    _cfg: { spenttimeDuration },
  } = dot;

  if (!spenttimeState) {
    // spenttime was already canceled
    return;
  }

  const { intervalSequence } = _createIntervalSequence(SPENTTIME_INTERVAL_CONFIG, spenttimeDuration);

  const lastTotalSpenttime = intervalSequence.slice(0, spenttimeState.i).reduce((acc, val) => acc + val, 0);

  // Spenttime stops hitting after duration
  if (lastTotalSpenttime >= spenttimeDuration || !intervalSequence.length) {
    return;
  }

  const nextIntervalTime = (intervalSequence[spenttimeState.i] || intervalSequence[intervalSequence.length - 1]) * 1000;

  // if the interval was cleared, we need calculate elapsed time before interval reset
  const elapsedTime = spenttimeState.intervalId ? 0 : (spenttimeState.pageTime - lastTotalSpenttime) * 1000;

  spenttimeState.intervalId = setTimeout((): void => {
    spenttimeState.data.d.type = 'regular';
    spenttimeState.i++;

    const currentTotalSpenttime = intervalSequence.slice(0, spenttimeState.i).reduce((acc, val) => acc + val, 0);

    _hit(dot, spenttimeState.data, currentTotalSpenttime);
    _createInterval(dot);
  }, nextIntervalTime - elapsedTime);
};

/**
 * Returns current timestamp
 * @return {number} Current timestamp in seconds
 */
export const _getCurrentTime = (): number => {
  return new Date().getTime() / 1000;
};

/**
 * Vytvori spenttime objekt
 */
export const _init = (dot: DOT, data: Data): void => {
  dot.spenttimeState = {
    data,
    i: 0,
    intervalId: null,
    lastVisit: _getCurrentTime(),
    visibilitychangeListener: null,
    pageTime: 0,
    blurEventCalled: false,
    unloadListener: null,
  };
};

/**
 * Visibilitychange event handler (window minimalization, tab switching)
 */
export const _handleVisibilityChange = (dot: DOT): void => {
  if (isPageVisible()) {
    // change to visible
    dot.spenttimeState.lastVisit = _getCurrentTime();
    dot.spenttimeState.blurEventCalled = false;
    _createInterval(dot);
  } else if (!dot.spenttimeState.blurEventCalled) {
    // change to hidden
    clearSpenttimeInterval(dot);
    dot.spenttimeState.pageTime += _getCurrentTime() - dot.spenttimeState.lastVisit;
    _blurHit(dot, dot.spenttimeState.data, dot.spenttimeState.pageTime);
    dot.spenttimeState.blurEventCalled = true;
  }
};

/**
 * Unload and Beforeunload event handler
 * @param dot DOT instance
 */
export const _handleUnload = (dot: DOT): void => {
  if (!dot.spenttimeState) {
    // spenttime was already canceled
    dot.log('spenttime: (_handleUnload) already canceled');
    return;
  }

  if (dot.spenttimeState.blurEventCalled) {
    // avoid double "blur hit" send
    dot.log('spenttime: double blur hit prevented');
    return;
  }

  dot.spenttimeState.blurEventCalled = true;
  dot.spenttimeState.pageTime += _getCurrentTime() - dot.spenttimeState.lastVisit;
  _blurHit(dot, dot.spenttimeState.data, dot.spenttimeState.pageTime);
};

// helper for temporary solution for migrating internal services to SSP initiated Spenttime
export const getSpenttimeInitiator = (data: Data): 'ssp' | string | undefined => {
  return data && data.initiator;
};

/**
 * Stops spenttime measurment and hitting
 */
export const cancelSpenttime = (dot: DOT): void => {
  clearSpenttimeInterval(dot);
  clearVisibilitychangeEvent(dot);

  dot.spenttimeState = null;
  dot.spenttimeConfigured = false;
};

/**
 * Start spenttime measurement and hitting.
 * Hits are sent as following: first hit 5s, second 10s, third 30s, then every 30sec till 4 minutes, then every minute till 15 minutes after that every 5 minutes till one hour
 * Measuring stops after one hour
 */
export const spenttime = (dot: DOT, data?: Data): void => {
  if (isPageHidden()) {
    _addToCallsQueue(dot, data);
    return;
  }

  // If dot.spenttimeState is not undefined (initialization didn't happen yet) or empty object (spenttime was cancelled),
  // it means the duplicate initialization and we need to avoid it.
  if (dot.spenttimeState && Object.keys(dot.spenttimeState).length) {
    // Cancel current running spenttime if the new one is initiated by SSP and the current one not.
    if (getSpenttimeInitiator(data) === 'ssp' && getSpenttimeInitiator(dot.spenttimeState?.data) !== 'ssp') {
      dot.log('spenttime: Canceling custom initiated spenttime and initiating by SSP.');
      cancelSpenttime(dot);
    }

    // Break new spenttime initiation if the old one is initiated by SSP and the new one not.
    if (getSpenttimeInitiator(data) !== 'ssp' && getSpenttimeInitiator(dot.spenttimeState?.data) === 'ssp') {
      dot.log('spenttime: Spenttime was already initiated by SSP. Terminating.');
      return;
    }
  }

  clearSpenttimeInterval(dot);

  if (!data?.d) {
    data = { ...(data || {}), d: {} };
  }

  const { d } = data;
  const dopAbVariant = getParameterFromURLHash('dop_ab_variant');
  const dopReqId = getParameterFromURLHash('dop_req_id');
  const dopSourceZoneName = getParameterFromURLHash('dop_source_zone_name');
  const dopId = getParameterFromURLHash('dop_id');
  const dopVertId = getParameterFromURLHash('dop_vert_id');
  const dopVertAb = getParameterFromURLHash('dop_vert_ab');

  let newUrl = window.location.href;

  /* doporucovani article ID has to overwrite data.d.id */
  if (dopId) {
    d.id = dopId;
    newUrl = URL.removeParam(newUrl, 'dop_id');
  }

  if (dopAbVariant) {
    // eslint-disable-next-line camelcase
    d.dop_ab_variant = dopAbVariant;
  }

  if (dopReqId) {
    // eslint-disable-next-line camelcase
    d.dop_req_id = dopReqId;
    newUrl = URL.removeParam(newUrl, 'dop_req_id');
  }

  if (dopSourceZoneName) {
    // eslint-disable-next-line camelcase
    d.dop_source_zone_name = dopSourceZoneName;
  }

  if (dopVertId) {
    // eslint-disable-next-line camelcase
    d.dop_vert_id = dopVertId;
    newUrl = URL.removeParam(newUrl, 'dop_vert_id');
  }

  if (dopVertAb) {
    // eslint-disable-next-line camelcase
    d.dop_vert_ab = dopVertAb;
    newUrl = URL.removeParam(newUrl, 'dop_vert_ab');
  }

  if (
    !!dot.getCfgValue('dopParamCleanup') &&
    window.location.href !== newUrl &&
    getSpenttimeInitiator(data) === 'ssp'
  ) {
    window.history.replaceState(window.history.state, '', newUrl);
  }

  if (data.atricleId) {
    d.articleId = data.atricleId;
  }

  if (!d.type) {
    d.type = 'initial';
  }

  _init(dot, data);

  if (!dot.spenttimeConfigured) {
    dot.spenttimeConfigured = true;
    dot.spenttimeState.visibilitychangeListener = (): void => _handleVisibilityChange(dot);
    dot.spenttimeState.unloadListener = (): void => _handleUnload(dot);
    document.addEventListener('visibilitychange', dot.spenttimeState.visibilitychangeListener);
    document.addEventListener('pagehide', dot.spenttimeState.visibilitychangeListener);
    window.addEventListener('unload', dot.spenttimeState.unloadListener);
    window.addEventListener('beforeunload', dot.spenttimeState.unloadListener);
  }

  if ('PerformancePaintTiming' in window) {
    _fcpHit(dot);
  }

  _createInterval(dot);
};

/**
 * Adds spenttime API to DOT instance
 * @param dot DOT instance
 */
export const addSpenttime = (dot: DOT): void => {
  dot.spenttime = (data?: Data): void => {
    spenttime(dot, data);
  };

  dot.cancelSpenttime = (): void => {
    cancelSpenttime(dot);
  };
};

export default {
  cancelSpenttime,
  clearSpenttimeInterval,
  clearVisibilitychangeEvent,
  spenttime,
  addSpenttime,
};
